<template>
  <div v-loading="loading" class="container">
    <div class="operate">
      <div class="title-content">
        <div class="title">
          {{ $route.query.name }}-各项指标对比汇总-{{ subTitle }}
        </div>
        <a-tooltip placement="top">
          <template slot="title">
            <span>采用{{ dataType === 1 ? "原始分" : "赋分" }}数据源统计</span>
          </template>
          <a-radio-group
            v-if="scoreType == 1 && $route.query.ruleId > 0"
            v-model="dataType"
            button-style="solid"
            size="small"
            @change="dataTypeChange"
          >
            <a-radio-button :value="1"> 原始分 </a-radio-button>
            <a-radio-button :value="2"> 赋分 </a-radio-button>
          </a-radio-group>
        </a-tooltip>
      </div>
      <div class="btns">
        <a-space>
          <a-button
            type="primary"
            ghost
            :loading="btnLoading"
            @click="downloadFile"
            >下载各项指标对比汇总
          </a-button>
        </a-space>
      </div>
    </div>
    <a-tabs v-model="type" type="card" @change="tabsChange">
      <a-tab-pane key="1" tab="对比汇总"> </a-tab-pane>
      <a-tab-pane key="2" tab="平均分"> </a-tab-pane>
      <a-tab-pane key="3" tab="得分率"> </a-tab-pane>
      <a-tab-pane v-for="item in ratiosList" :key="item.id" :tab="item.name">
      </a-tab-pane>
      <a-tab-pane key="4" tab="标准差"> </a-tab-pane>
      <a-tab-pane key="5" tab="中位数"> </a-tab-pane>
      <a-tab-pane key="6" tab="最高分"> </a-tab-pane>
    </a-tabs>
    <a-table
      bordered
      :row-key="(record) => record.id"
      :columns="columns"
      :scroll="{ x: 'max-content', y: tableX }"
      :data-source="tableData"
      :pagination="false"
    >
      <div
        v-for="(item, index) in subjectList"
        :key="index"
        :slot="`title${item.subjectId}`"
      >
        <div>{{ item.subjectName }}</div>
        <!-- <div style="color: #999">（原始分）</div> -->
      </div>
    </a-table>
  </div>
</template>

<script>
import {
  indicatorcomparesummary,
  indicatorcompareaveragescore,
  indicatorcomparescorerate,
  indicatorcomparestandarddeviation,
  indicatorcomparemedian,
  indicatorcomparehighestscore,
  indicatorcompareratio,
} from "@/core/api/academic/unionSheet";
import { indicatorcomparesummaryExport } from "@/core/api/academic/unionSheetExport";
import { getstatratiooption } from "@/core/api/academic/common";
import { mapState } from "vuex";
export default {
  name: "",
  data() {
    return {
      tableData: [],
      subTitle: "对比汇总",
      type: "1",
      dataType: 1,
      subjectList: [],
      ratiosList: [],
      tableX: window.innerHeight - 190,
      loading: false,
      btnLoading: false,
      summaryKey1Cols: [
        {
          title: "科目",
          dataIndex: "subjectName",
          width: 65,
          align: "center",
          customRender: (text, record, index) => {
            let targetIndex = this.tableData.findIndex(
              (item) => item.subjectName === record.subjectName
            );
            let arr = this.tableData.filter(
              (item) => item.subjectName === record.subjectName
            );
            let obj = {
              children: record.subjectName,
              attrs: {},
            };
            if (index == targetIndex) {
              obj.attrs.rowSpan = arr.length;
            } else {
              obj.attrs.rowSpan = 0;
            }
            return obj;
          },
        },
        {
          title: "学校代码",
          dataIndex: "schoolCode",
          width: 75,
          align: "center",
        },
        {
          title: "学校",
          dataIndex: "schoolName",
          width: 155,
          align: "center",
        },
        {
          title: "统计人数",
          dataIndex: "statTotal",
          width: 75,
          align: "center",
        },
        {
          title: "满分人数",
          dataIndex: "countRight",
          width: 75,
          align: "center",
        },
        {
          title: "最高分",
          dataIndex: "highestScore",
          width: 65,
          align: "center",
        },
        {
          title: "最低分",
          dataIndex: "lowestScore",
          width: 65,
          align: "center",
        },
        {
          title: "全距",
          dataIndex: "rangeScore",
          width: 65,
          align: "center",
        },
        {
          title: "中位数",
          dataIndex: "median",
          width: 65,
          align: "center",
        },
        {
          title: "离均差",
          dataIndex: "deviationFromMean",
          width: 70,
          align: "center",
        },
        {
          title: "超均率",
          dataIndex: "overAverageRate",
          width: 75,
          align: "center",
          customRender: (text) => (text ? text + "%" : ""),
        },
        {
          title: "标准差",
          dataIndex: "standardDeviation",
          width: 65,
          align: "center",
        },
      ],
      columns: [
        {
          title: "原始分",
          dataIndex: "ori",
          align: "center",
          children: [
            {
              title: "平均分",
              dataIndex: "averageScoreOri",
              width: 65,
              align: "center",
            },
            {
              title: "排名",
              dataIndex: "rankOri",
              width: 55,
              align: "center",
            },
          ],
        },
        {
          title: "赋分",
          dataIndex: "ap",
          align: "center",
          children: [
            {
              title: "平均分",
              dataIndex: "averageScore",
              width: 65,
              align: "center",
            },
            {
              title: "排名",
              dataIndex: "rank ",
              width: 55,
              align: "center",
            },
          ],
        },
      ],
    };
  },
  computed: {
    ...mapState({
      scoreType: (state) => state.report.scoreType,
    }),
  },
  created() {
    this.getRatiosList();
  },
  methods: {
    async downloadFile() {
      try {
        this.btnLoading = true;
        const res = await indicatorcomparesummaryExport({
          id: this.$route.query.id,
          dataSource: this.dataType,
        });
        const { name, url } = res.data.data;
        const { href } = this.$router.resolve({
          path: "/downFile",
          query: {
            url: url,
            name: name,
            type: 1,
          },
        });
        let timer = setTimeout(() => {
          window.open(href, "_blank");
          clearTimeout(timer);
        }, 0);
        this.btnLoading = false;
      } catch {
        this.btnLoading = false;
      }
    },
    async getRatiosList() {
      const res = await getstatratiooption({ id: this.$route.query.id });
      this.ratiosList = res.data.data;
      this.getKey1Data();
    },
    dataTypeChange() {
      this.tabsChange();
    },
    async getKey1Data() {
      try {
        this.loading = true;
        const res = await indicatorcomparesummary({
          id: this.$route.query.id,
          dataSource:
            this.$route.query.ruleId > 0 && this.scoreType == 1
              ? this.dataType
              : 1,
        });
        this.tableData = res.data.data;

        this.columns = [
          ...this.summaryKey1Cols,
          ...this.ratiosList.map((item, index) => ({
            title: item.name,
            align: "center",
            dataIndex: "ratios" + index,
            children: [
              {
                title: "人数",
                align: "center",
                dataIndex: "total" + index,
                width: 60,
                customRender: (text, record) => record.ratios[index].total,
              },
              {
                title: "比率",
                align: "center",
                dataIndex: "proportion" + index,
                width: 60,
                customRender: (text, record) => record.ratios[index].proportion,
              },
              {
                title: "排名",
                align: "center",
                dataIndex: "rank" + index,
                width: 45,
                customRender: (text, record) => record.ratios[index].rank,
              },
            ],
          })),
          {
            title: "平均分",
            align: "center",
            dataIndex: "averageScore",
            width: 70,
            customRender: (text, record) =>
              this.dataType == 1 ? record.averageScoreOri : record.averageScore,
          },
          {
            title: "排名",
            align: "center",
            width: 55,
            dataIndex: "rank",
            customRender: (text, record) =>
              this.dataType == 1
                ? record.averageScoreOriRank
                : record.averageScoreRank,
          },
        ];
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    async getKey2Data() {
      try {
        this.loading = true;
        const res = await indicatorcompareaveragescore({
          id: this.$route.query.id,
          dataSource:
            this.$route.query.ruleId > 0 && this.scoreType == 1
              ? this.dataType
              : 1,
        });
        let cols = [];
        this.subjectList = [];
        if (res.data.data.length) {
          cols = res.data.data[0].subjects;
          this.subjectList = res.data.data[0].subjects;
        }
        this.columns = [
          {
            title: "学校",
            align: "center",
            width: 160,
            dataIndex: "schoolName",
          },
          ...cols.map((item, index) => {
            if (item.isAp === 0) {
              return {
                align: "center",
                dataIndex: "score" + index,
                width: 100,
                customRender: (text, record) => record.subjects[index].value,
                scopedSlots: {
                  title: `title${item.subjectId}`,
                },
              };
            } else {
              return {
                title: item.subjectName,
                width: 100,
                align: "center",
                dataIndex: "score" + index,
                customRender: (text, record) => record.subjects[index].value,
              };
            }
          }),
        ];
        this.tableData = res.data.data;
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    async getKey3Data() {
      try {
        this.loading = true;
        const res = await indicatorcomparescorerate({
          id: this.$route.query.id,
          dataSource:
            this.$route.query.ruleId > 0 && this.scoreType == 1
              ? this.dataType
              : 1,
        });
        let cols = [];
        this.subjectList = [];
        if (res.data.data.length) {
          cols = res.data.data[0].subjects;
          this.subjectList = res.data.data[0].subjects;
        }
        this.columns = [
          {
            title: "学校",
            align: "center",
            width: 100,
            dataIndex: "schoolName",
          },
          ...cols.map((item, index) => {
            if (item.isAp === 0) {
              return {
                align: "center",
                width: 100,
                dataIndex: "score" + index,
                customRender: (text, record) => record.subjects[index].value,
                scopedSlots: {
                  title: `title${item.subjectId}`,
                },
              };
            } else {
              return {
                title: item.subjectName,
                width: 100,
                align: "center",
                dataIndex: "score" + index,
                customRender: (text, record) => record.subjects[index].value,
              };
            }
          }),
        ];
        this.tableData = res.data.data;
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    async getKey4Data() {
      try {
        this.loading = true;
        const res = await indicatorcomparestandarddeviation({
          id: this.$route.query.id,
          dataSource:
            this.$route.query.ruleId > 0 && this.scoreType == 1
              ? this.dataType
              : 1,
        });
        let cols = [];
        this.subjectList = [];
        if (res.data.data.length) {
          cols = res.data.data[0].subjects;
          this.subjectList = res.data.data[0].subjects;
        }
        this.columns = [
          {
            title: "学校",
            align: "center",
            width: 160,
            dataIndex: "schoolName",
          },
          ...cols.map((item, index) => {
            if (item.isAp === 0) {
              return {
                title: item.subjectName,
                width: 100,
                align: "center",
                dataIndex: "score" + index,
                customRender: (text, record) => record.subjects[index].value,
                scopedSlots: {
                  title: `title${item.subjectId}`,
                },
              };
            } else {
              return {
                title: item.subjectName,
                align: "center",
                width: 100,
                dataIndex: "score" + index,
                customRender: (text, record) => record.subjects[index].value,
              };
            }
          }),
        ];
        this.tableData = res.data.data;
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    async getKey5Data() {
      try {
        this.loading = true;
        const res = await indicatorcomparemedian({
          id: this.$route.query.id,
          dataSource:
            this.$route.query.ruleId > 0 && this.scoreType == 1
              ? this.dataType
              : 1,
        });
        let cols = [];
        this.subjectList = [];
        if (res.data.data.length) {
          cols = res.data.data[0].subjects;
          this.subjectList = res.data.data[0].subjects;
        }
        this.columns = [
          {
            title: "学校",
            align: "center",
            width: 160,
            dataIndex: "schoolName",
          },
          ...cols.map((item, index) => {
            if (item.isAp === 0) {
              return {
                align: "center",
                dataIndex: "score" + index,
                width: 100,
                customRender: (text, record) => record.subjects[index].value,
                scopedSlots: {
                  title: "title" + item.subjectId,
                },
              };
            } else {
              return {
                title: item.subjectName,
                align: "center",
                width: 100,
                dataIndex: "score" + index,
                customRender: (text, record) => record.subjects[index].value,
              };
            }
          }),
        ];
        this.tableData = res.data.data;
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    async getKey6Data() {
      try {
        this.loading = true;
        const res = await indicatorcomparehighestscore({
          id: this.$route.query.id,
          dataSource:
            this.$route.query.ruleId > 0 && this.scoreType == 1
              ? this.dataType
              : 1,
        });
        let cols = [];
        this.subjectList = [];
        if (res.data.data.length) {
          cols = res.data.data[0].subjects;
          this.subjectList = res.data.data[0].subjects;
        }
        this.columns = [
          {
            title: "学校",
            align: "center",
            width: 160,
            dataIndex: "schoolName",
          },
          ...cols.map((item, index) => {
            if (item.isAp === 0) {
              return {
                align: "center",
                width: 100,
                dataIndex: "score" + index,
                customRender: (text, record) => record.subjects[index].value,
                scopedSlots: {
                  title: `title${item.subjectId}`,
                },
              };
            } else {
              return {
                title: item.subjectName,
                align: "center",
                width: 100,
                dataIndex: "score" + index,
                customRender: (text, record) => record.subjects[index].value,
              };
            }
          }),
        ];
        this.tableData = res.data.data;
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },

    tabsChange() {
      const enmu = {
        1: this.getKey1Data,
        2: this.getKey2Data,
        3: this.getKey3Data,
        4: this.getKey4Data,
        5: this.getKey5Data,
        6: this.getKey6Data,
      };
      const titleEnmu = {
        1: "对比汇总",
        2: "平均分",
        3: "得分率",
        4: "标准差",
        5: "中位数",
        6: "最高分",
      };
      if (enmu[this.type]) {
        enmu[this.type]();
        this.subTitle = titleEnmu[this.type];
      } else {
        this.subTitle = this.ratiosList.find(
          (item) => item.id === this.type
        ).name;
        this.getRatiosData();
      }
    },
    async getRatiosData() {
      try {
        this.loading = true;
        const res = await indicatorcompareratio({
          id: this.$route.query.id,
          dataSource:
            this.$route.query.ruleId > 0 && this.scoreType == 1
              ? this.dataType
              : 1,
          ratioId: this.type,
        });
        let cols = [];
        this.subjectList = [];
        if (res.data.data.length) {
          cols = res.data.data[0].subjects;
          this.subjectList = res.data.data[0].subjects;
        }
        this.columns = [
          {
            title: "学校",
            align: "center",
            width: 155,
            dataIndex: "schoolName",
          },
          ...cols.map((item, index) => {
            if (item.isAp === 0) {
              return {
                align: "center",
                dataIndex: "score" + index,
                customRender: (text, record) => record.subjects[index].value,
                width: 100,
                scopedSlots: {
                  title: `title${item.subjectId}`,
                },
              };
            } else {
              return {
                title: item.subjectName,
                align: "center",
                width: 100,
                dataIndex: "score" + index,
                customRender: (text, record) => record.subjects[index].value,
              };
            }
          }),
        ];
        this.tableData = res.data.data;
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  .operate {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    .title-content {
      display: flex;
      align-items: center;
      .title {
        font-size: 14px;
        color: #0a1119;
        font-weight: 600;
        margin-right: 8px;
      }
    }
  }
}
::v-deep .ant-tabs-bar {
  margin: 0 0 0 0 !important;
}
.charts-box {
  width: 100%;
  .charts {
    width: 100%;
    height: 650px;
  }
}
::v-deep .ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 4px 6px !important;
}
::v-deep .ant-table-row-cell-break-word {
  padding: 4px 6px !important;
}
</style>
